import { createGlobalStyle } from 'styled-components';
import tw from 'tailwind.macro';

export default createGlobalStyle`
  body {
    ${tw`m-0 text-indigo-900 bg-white text-base`};
  }

  a {
    ${tw`text-indigo-600 hover:text-indigo-700`};
  }

  p + p {
    ${tw`mt-3`};
  }

  @screen sm {
    .markdown {
      ${tw`text-base`};
    }
  }

  @screen lg {
    .markdown {
      ${tw`text-lg`};
    }
  }

  .markdown {
    ${tw`leading-relaxed text-sm`};
  }

  .markdown blockquote {
    ${tw`p-2 mx-2 bg-indigo-100 mb-4 border-l-4 border-indigo-400 italic`};
  }
  .markdown blockquote > p {
    ${tw`mb-0 `};
  }

  .markdown p {
    ${tw`mb-4 text-base`};
  }

  .markdown h1 {
    ${tw`text-3xl my-6 font-semibold text-indigo-700`};
  }
  .markdown h2 {
    ${tw`text-2xl my-6 font-semibold text-indigo-600`};
  }
  .markdown h3,
  .markdown h4{
    ${tw`text-xl my-3 font-semibold text-indigo-600`};
  }
  .markdown h5,
  .markdown h6 {
    ${tw`text-lg my-3 font-semibold text-indigo-600`};
  }

  .markdown a {
    ${tw`text-indigo-600`};
  }
  .markdown a:hover {
    ${tw`underline`};
  }

  .markdown ul,
  .markdown ol {
    ${tw`mb-4 ml-8`};
  }
  .markdown li > p,
  .markdown li > ul,
  .markdown li > ol {
    ${tw`mb-0`};
  }
  .markdown ol {
    ${tw`list-decimal`};
  }
  .markdown ul {
    ${tw`list-disc`};
  }

  .markdown td,
  .markdown th {
    ${tw`px-2 py-1 border border-gray-400`};
  }
  .markdown tr:nth-child(odd) {
    ${tw`bg-gray-100`};
  }
  .markdown table {
    ${tw`mb-6`};
  }

  .markdown : not(pre) > code.language - text {
    white - space: pre - line;
  }

  .markdown pre {
    background-color: #373434;
    color: #8690e3;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
  }
`;
