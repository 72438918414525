import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { IconProps } from 'components/ui/Icon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Styled from './styles';

interface Link {
  link: string;
  icon: IconProps;
}

const SocialLinks: React.FC = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {category: {eq: "social section"}}}) {
        edges {
          node {
            frontmatter {
              links {
                icon
                link
              }
            }
          }
        }
      }
    }
  `);

  const socialLinks: Link[] = allMarkdownRemark.edges[0].node.frontmatter.links;

  return (
    <Styled.SocialLinks>
      {socialLinks.map((item) => {
        const iconName = ['fab', item.icon];
        return (
          <Styled.SocialItem key={item.icon} >
            <Styled.IconLink href={item.link} target='_blank'>
              <Styled.Icon>
                <FontAwesomeIcon icon={iconName} />
              </Styled.Icon>
            </Styled.IconLink>
          </Styled.SocialItem>
        );
      })}
    </Styled.SocialLinks>
  );
};

export default SocialLinks;
